import {React} from 'react'
import 'flowbite'
const InfoConsulting = () => {
    return (
        <div className='bg-gray-200 py-7'>
            <div className="container mx-auto grid grid-cols-2 gap-6">
                <div className='grid'>
                    <div className='min-w-[70%] max-w-[70%] my-auto'>
                        <p className='text-4xl'>NÃO SOMOS UMA CONSULTORIA.</p>
                        <p className='font-bold text-4xl'>VAMOS BEM ALÉM DISSO.</p>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <p className='font-bold'>PADRONIZAÇÃO</p>
                        <p>Definimos especificações técnicas. Dessa forma, criamos a padronização dos processos e fluxos para auxiliar no aumento da compatibilidade, reprodutibilidade e segurança dos negócios.</p>
                    </div>
                    <div>
                        <p className='font-bold'>REPLICAÇÃO</p>
                        <p>Atuamos em todos os setores do negócio para assegurar o fluxo convergente das ações de cada departamento, replicando e padronizando cada área da empresa, garantido a escalabilidade dos clientes que atendemos.</p>
                    </div>
                    <div>
                        <p className='font-bold'>OPERACIONALIZAÇÃO</p>
                        <p>Implementamos ferramentas estruturadas para o processo de suporte e sustentação dos sistemas através da operacionalização, no qual propomos melhorias e visibilidade do andamento dos trabalhos.</p>
                    </div>
                    <div>
                        <p className='font-bold'>ECONOMIA</p>
                        <p>Com visão técnica do negócio alinhada à boas práticas do mercado, otimizamos fluxos para agregar valor aos clientes, reduzindo custos e retrabalhos.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}        
export default InfoConsulting
        