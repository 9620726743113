import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Services from "../pages/Services";
import CheckCoverage from "../pages/CheckCoverage";
import Contact from "../pages/Contact";

const Body = () => {
   return(
    <BrowserRouter>
        <Routes>
            <Route element={ <Home/> }  path="/" exact />
            <Route element={ <AboutUs/> }  path="/sobre-nos" exact />
            <Route element={ <Services/> }  path="/servicos" exact />
            <Route element={ <CheckCoverage/> }  path="/cobertura" exact />
            <Route element={ <Contact/> }  path="/contato" exact />
        </Routes>
    </BrowserRouter>
   )
}

export default Body;