import {React} from 'react'
import 'flowbite'
import mainLogo from '../../_assets/img/logo/logo_t2web.png'
const Footer = () => {
    return (
        <div>
<footer class="p-4 bg-[#06324B] shadow md:px-6 md:py-8 dark:bg-gray-900 hidden md:block">
            <div class="sm:flex sm:items-center sm:justify-between">
                <a href="https://flowbite.com/" class="flex items-center mb-4 sm:mb-0">
                    <img src={mainLogo} class="mr-3 h-12" alt="Flowbite Logo"/>
                </a>
                <ul class="flex flex-wrap items-center mb-6 text-sm text-white sm:mb-0 dark:text-gray-400">
                    <li>
                        <a href="/" class="mr-4 hover:underline md:mr-6 ">Inicio</a>
                    </li>
                    <li>
                        <a href="/sobre-nos" class="mr-4 hover:underline md:mr-6">Sobre nós</a>
                    </li>
                    <li>
                        <a href="/servicos" class="mr-4 hover:underline md:mr-6 ">Serviços</a>
                    </li>
                    <li>
                        <a href="/cobertura" class="hover:underline md:mr-6 ">Verificar cobertura</a>
                    </li>
                    <li>
                        <a href="/contato" class="hover:underline md:mr-2">Contato</a>
                    </li>
                </ul>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
            <span class="block text-sm text-white sm:text-center dark:text-white">© 2022 <a href="#t2Web" class="hover:underline">T2Web</a>. Todos os direitos reservados.
            </span>
        </footer>
        <footer class="p-4 bg-[#06324B] shadow md:px-6 md:py-8 dark:bg-gray-900 mx-auto block md:hidden">
                <ul class="items-center mb-6 text-sm text-white sm:mb-0 dark:text-gray-400 flex flex-col">
                        <a href="https://flowbite.com/" class="flex items-center mb-4 sm:mb-0">
                            <img src={mainLogo} class="mr-3 h-12" alt="Flowbite Logo"/>
                        </a>
                    <br/>
                        <a href="/" class="mr-4 hover:underline md:mr-6 ">Inicio</a>
                    <br/>
                        <a href="/sobre-nos" class="mr-4 hover:underline md:mr-6">Sobre nós</a>
                    <br/>
                        <a href="/servicos" class="mr-4 hover:underline md:mr-6 ">Serviços</a>
                    <br/>
                        <a href="/cobertura" class="hover:underline md:mr-6 ">Verificar cobertura</a>
                    <br/>
                        <a href="/contato" class="hover:underline md:mr-2">Contato</a>
                </ul>
            <hr class="my-6 border-gray-200 mx-auto dark:border-gray-700 lg:my-8"/>
            <span class="block text-sm text-white text-center dark:text-white">© 2022 <a href="#t2Web" class="hover:underline">T2Web</a>. Todos os direitos reservados.
            </span>
        </footer>
        </div>
        

    )
}        
export default Footer
        