import React from 'react';
import search from '../_assets/icones/magnifying-glass-location-solid.svg'
import Navbar from '../components/navbar/index'

const CheckCoverage = () =>{
  return (
    <div>
        <Navbar />
      <div className="container mx-auto">
        <h1 className="uppercase align-center text-center mt-[50px] mb-[20px] text-[40px] text-[#02495A] font-bold"> VERIFICAR COBERTURA</h1>
        <div className="grid grid-cols-2 gap-6">
            <div className='my-8'>
                <img src={search} className="max-w-[400px] mx-auto"/>
            </div>
            <div className='max-w-[400px] my-auto'>
                <p className='text-[25px] text-[#02495A] font-bold my-4'>Quer verificar a cobertura de fibra ótica?</p>
                <p className='my-4'>Para confirmar a disponibilidade de rede de fibra ótica da T2Web na sua morada insira, por favor, o código postal.</p>
                <form className="w-full max-w-lg my-2">
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="cep">
                                    Buscar CEP:
                                </label>
                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="cep" type="text" placeholder="00000000-000" required/>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6 md:mb-0 grid justify-items-end">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </form>
            </div>
        </div>
      </div>
    </div>
  );
}

export default CheckCoverage;