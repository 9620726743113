import React from 'react';
import Contact_us from '../components/contact_us';
import CardTextLeft from '../components/cardTextLeft';
import CardTextRight from '../components/cardTextRight';
import InfoConsulting from '../components/infoConsulting';
import corparative from '../_assets/img/about_us/corparative.jpg'
import monitoring from '../_assets/img/about_us/monitoring.jpg'
import mountain from '../_assets/img/about_us/mountain.jpg'
import teclado from '../_assets/img/about_us/teclado.jpg'
import Navbar from '../components/navbar/index'

const AboutUs = () =>{
  return (
    <div>
        <Navbar />
        <div className="container mx-auto">
            <h1 className="uppercase align-center text-center mt-[50px] mb-[20px] text-[40px] text-[#02495A] font-bold">CONHEÇA MAIS SOBRE NÓS</h1>
            <p className="leading-loose">Somos formados por profissionais que se desenvolveram dentro de provedores de internet e que, em algum momento de nossas carreiras, atuamos juntos.</p>
            <p className="leading-loose">Em 2015, atendendo a demanda do mercado, agrupamos gestores especializados em cada um dos serviços que prestamos, desenvolvemos metodologias de gestão que nos permitissem trabalhar simultaneamente para inúmeras empresas, e fundamos a Metro Network.</p>
            <p className="leading-loose">Nosso foco é a excelência dos serviços e evolução constante de nossos clientes, sempre prezando pela humildade, ética e bom relacionamento.</p>
            <CardTextLeft title="ACONSELHAMENTO TÉCNICO" text="A T2Web possui um time de profissionais multidisciplinares, atentos ao mínimos detalhes e capacitados para identificarem as melhores soluções para otimização e crescimento dos clientes atendidos." img={corparative}/>
            <CardTextRight title="PLANEJAR E MONITORAR" text="Todos os nossos serviços exigem planejamento. Na T2Web realizamos um diagnóstico completo e definimos estratégias que vão nortear as soluções que serão necessárias para o crescimento, otimização do negócio e a sustentabilidade dos nossos clientes." img={monitoring}/>
            <CardTextLeft title="PROJETOS" text="Nossos profissionais especializados estudam minuciosamente o negócio dos nossos clientes para a implementação de projetos 360º, no qual cada detalhe é planejado para tornar o negócio do cliente sustentável em todas as áreas da organização. Planejamos, organizamos e o cliente fica no controle de tudo." img={mountain}/>
        </div>
        <InfoConsulting />
        <div className="container mx-auto my-5">
            <div className="grid grid-cols-2 gap-6">
                <img src={teclado}/>
                <div className='my-auto'>
                    <div className='my-5'>
                        <p className='font-bold'>01 - PROFISSIONALISMO</p>
                        <p className='leading-7'>Nossos profissionais entendem que o bem da nossa empresa está em fazer o melhor aos nossos clientes. Nós da Metro acreditamos que o maior potencial está em nosso time, verdadeiros entusiastas que reconhecem que a proatividade é ir além da expectativa.</p>
                    </div>
                    <div className='my-5'>
                        <p className='font-bold'>02 - AUTENTICIDADE</p>
                        <p className='leading-7'>Nossos networkers têm a missão de sempre entender a realidade dos clientes e a partir disso aplicar todas as técnicas, com humildade e respeito. Afinal, afeto e conhecimento não podem ser guardados. Devem ser divididos.</p>
                    </div>
                    <div className='my-5'>
                        <p className='font-bold'>03 - INTEGRIDADE</p>
                        <p className='leading-7'>Independente de horário, dia da semana ou feriado, temos em nosso DNA a integridade como algo que mantém as empresas dos nossos clientes funcionando. Cada um com o seu objetivo, todos unidos para somar nos projetos.</p>
                    </div>
                </div>
            </div>
            <Contact_us />
        </div>
    </div>
  );
}

export default AboutUs;