import React from 'react';
import BannerFullPage from '../components/bannerFullPage'
import Services from '../components/services';
import NetworkAndCoverage from '../components/networkAndCoverage';
import Customers from '../components/customers';
import Contact_us from '../components/contact_us';

const Home = () =>{
  return (
    <div>
      <BannerFullPage />
      <div className="container mx-auto">
        <Services />
        <NetworkAndCoverage />
        <Contact_us />
      </div>
    </div>
  );
}

export default Home;