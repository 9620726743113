import {React} from 'react'
import 'flowbite'
const Service = (props) => {
    return (
        <div className="w-full max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-5 mx-auto">
            <div className="flex flex-col items-center pb-10">
                <img className="mb-3 w-24 h-24 rounded-full shadow-lg" src={props.logo} alt="Bonnie image"/>
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{props.title}</h5>
                <span className="text-sm text-gray-500 dark:text-gray-400">{props.subTitle}</span>
                <div className="flex mt-4 space-x-3 md:mt-6">
                    <a href="#" className="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Mais informações</a>
                </div>
            </div>
        </div>
    )
}        
export default Service