import {React} from 'react'
import 'flowbite'
import Service from '../service'
import conectividadeImg from '../../_assets/img/services/conectividade.png'
import clusteringImg from '../../_assets/img/services/icon-clustering.png'
import houseImg from '../../_assets/img/services/house.png'
import neutroImg from '../../_assets/img/services/neutro.png'
import condultaImg from '../../_assets/img/services/condulta.png'

const Services = () => {
    return (
        <div>
            <h1 class="uppercase align-center text-center mt-[50px] mb-[50px] text-[40px] text-[#02495A] font-bold">SERVIÇOS</h1>
            <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4 mx-[30px] md:mx-[0px]">
                <Service title="BITSTREAM" subTitle="Serviço avançado de conetividade" logo={conectividadeImg} />
                <Service title="CIRCUITOS ETHERNET" subTitle="Serviço de conetividade P2P" logo={clusteringImg} />
                <Service title="HOUSING" subTitle="Serviço de aluguer de espaço técnico" logo={houseImg} />
                <Service title="FIBRA ESCURA" subTitle="Serviço de aluguer de fibra escura em diversas topologias" logo={neutroImg} />
                <Service title="CONDUTAS E POSTES" subTitle="Serviço de utilização de condutas e postes" logo={condultaImg} />
            </div>
        </div>
    )
}        
export default Services
