import React from 'react';
import Contact_us from '../components/contact_us';
import Navbar from '../components/navbar/index'

const Contact = () =>{
  return (
    <div>
      <Navbar />
      <div className="container mx-auto">
        <Contact_us />
      </div>
    </div>
  );
}

export default Contact;