import {React} from 'react'
import 'flowbite'
const CardTextLeft = (props) => {
    let img = props.img
    return (
        <div className='my-8'>
            <div className="max-w-sm w-full lg:max-w-full lg:flex min-h-[157px]">
                <div style={{backgroundImage: `url(${img})`}} className='h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden' title={props.title}>
                </div>
                <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal w-full">
                    <div className="mb-8">
                    <div className="text-gray-900 font-bold text-xl mb-2">{props.title}</div>
                    <p className="text-gray-700 text-base">{props.text}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}        
export default CardTextLeft