import {React} from 'react'
import 'flowbite'
const Contact_us = (props) => {
    return (
        <div className='my-8'>
            <h1 className="uppercase align-center text-center mt-[50px] mb-[50px] text-[40px] text-[#02495A] font-bold">Central de atendimento</h1>
            <div className="grid sm:grid-cols-1 md:grid-cols-6 gap-6">
                <div></div>
                <div className='grid sm:ml-8 md:justify-items-end hidden md:block col-span-2'>
                    <div>
                    <p className='text-[40px] text-[#02495A] font-bold mb-6'>Email:</p>
                    <p className='my-2 text-[25px]'>contato@t2web.com.br</p>
                    <p className='text-[40px] text-[#02495A] font-bold my-6'>Telefone:</p>
                    <p className='my-2 text-[25px]'>(82) 3275-9090</p>
                    <p className='text-[40px] text-[#02495A] font-bold my-6'>Whatsapp:</p>
                    <p className='my-2 text-[25px]'>(82) 99928-9928</p>
                    </div>
                </div>
                <div className='col-span-2'>
                    <form className="w-full max-w-lgmx-auto">
                        <div className="flex flex-wrap mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="name">
                                    Nome
                                </label>
                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="name" type="text" placeholder="Wesley da Silva" required/>
                            </div>
                        </div>
                        <div className="flex flex-wrap mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="email">
                                    Email
                                </label>
                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="email" type="email" placeholder="exemplo@gmail.com" required/>
                            </div>
                        </div>
                        <div className="flex flex-wrap mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="telefone">
                                    Telefone
                                </label>
                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="telefone" type="number" placeholder="82 996728136" required/>
                            </div>
                        </div>
                        <div className="flex flex-wrap mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="message">
                                    Mensagem
                                </label>
                                <textarea id="message" className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" placeholder='Digite sua mensagem' required></textarea>
                            </div>
                        </div>
                        <div className="flex flex-wrap mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6 md:mb-0 grid justify-items-end">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
                                    Enviar mensagem
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div></div>

            </div>
        </div>
    )
}        
export default Contact_us