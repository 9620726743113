import '../_assets/css/index.css'
import '../_assets/css/tailwind.css'
import 'flowbite'
import Body from "./routes";
import Footer from '../components/footer';
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

function App() {
  return (
    <div>
      <Body/>
      <Footer />
    </div>
  );
}

export default App;
