import {React} from 'react'
import 'flowbite'
import redeImg from '../../_assets/img/networkAndCoverage/mapa_linhas.png'

const NetworkAndCoverage = (props) => {
    return (
        <div className='my-8'>
            <h1 className="uppercase align-center text-center mt-[50px] mb-[50px] text-[40px] text-[#02495A] font-bold">Rede e cobertura</h1>
            <div className="grid sm:grid-cols-1 md:grid-cols-4 gap-4">
                <div className="mx-auto">
                    <p className='text-[40px] text-[#02495A] font-bold text-center'>600</p>
                    <p className='text-center'>Mil habitações cobertas</p>
                </div>
                <div className="mx-auto">
                    <p className='text-[40px] text-[#02495A] font-bold text-center'>111</p>
                    <p className='text-center'>Municípios</p>
                </div>
                <div className="mx-auto">
                    <p className='text-[40px] text-[#02495A] font-bold text-center'>6</p>
                    <p className='text-center'>Operadores na rede</p>
                </div>
                <div  className="mx-auto">
                    <p className='text-[40px] text-[#02495A] font-bold text-center'>40</p>
                    <p className='text-center'>Parques Industriais</p>
                </div>
            </div>
            <div className='mt-5 relative'>
                <img src={redeImg} className="mx-auto w-full"/>
            </div>
        </div>
    )
}        
export default NetworkAndCoverage