import {React} from 'react'
import 'flowbite'
import './index.css'
import imgBannerOne from '../../_assets/img/banner/home_final.jpg'
import Navbar from '../navbar/index'

const BannerFullPage = () => {
    return (
        <div class="bg" style={{backgroundImage: `url(${imgBannerOne})`}}>
            <Navbar />
            <span class="absolute text-2xl font-semibold text-white -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 sm:text-3xl dark:text-gray-800 text-banner">T2Web sai na frente mais uma vez com rede neutra</span>
        </div>
    )
}        
export default BannerFullPage
        