import React from 'react';
import Banner from '../components/banner'
import Services from '../components/services';
import NetworkAndCoverage from '../components/networkAndCoverage';
import Customers from '../components/customers';
import Contact_us from '../components/contact_us';
import Navbar from '../components/navbar/index'

const ServicesPage = () =>{
  return (
    <div>
      <Navbar />
      <div className="container mx-auto">
        <Services />
      </div>
    </div>
  );
}

export default ServicesPage;